<template>
  <li>
    <component class="footer-item" :is="tag" v-bind="$attrs"><slot/></component>
  </li>
</template>

<script>
export default {
  name: 'FooterNavigationItem',
  inheritAttrs: false,
  computed: {
    tag () {
      return Object.keys(this.$attrs).includes('href') ? 'a' : 'span'
    }
  }
}
</script>

<style lang="scss">
$footernavigation-item-margin: 1px 0 !default;
$footernavigation-item-padding-y: 0 !default;
$footernavigation-item-padding-x: $spacer * 0.25 !default;
$footernavigation-item-font-weight: $font-weight-bold !default;
$footernavigation-item-font-size: $font-size-base * 0.625 !default;
$footernavigation-item-color: $white !default;

$footernavigation-mobile-breakpoint: $mobile-breakpoint !default;

$footernavigation-item-mobile-margin: $footernavigation-item-margin !default;
$footernavigation-item-mobile-padding-y: $footernavigation-item-padding-y !default;
$footernavigation-item-mobile-padding-x: $footernavigation-item-padding-x !default;
$footernavigation-item-mobile-font-weight: $footernavigation-item-font-weight !default;
$footernavigation-item-mobile-font-size: $font-size-base * 0.5 !default;
$footernavigation-item-mobile-color: $footernavigation-item-color !default;

.footernavigation {
  li {
    > .footer-item {
      display: block;
      margin: $footernavigation-item-margin;
      padding: $footernavigation-item-padding-y $footernavigation-item-padding-x;
      border-left: 1px solid $footernavigation-item-color;
      font-weight: $footernavigation-item-font-weight;
      font-size: $footernavigation-item-font-size;
      color: $footernavigation-item-color;
      white-space: nowrap;
    }

    &:first-child {
      > .footer-item {
        padding-left: 0;
        border-left: 0 none;
      }
    }

    &:last-child {
      > .footer-item {
        padding-right: 0;
      }
    }

    @include media-breakpoint-down($footernavigation-mobile-breakpoint) {
      > .footer-item {
        margin: $footernavigation-item-mobile-margin;
        padding: $footernavigation-item-mobile-padding-y $footernavigation-item-mobile-padding-x;
        font-weight: $footernavigation-item-mobile-font-weight;
        font-size: $footernavigation-item-mobile-font-size;
        color: $footernavigation-item-mobile-color;
      }
    }
  }
}
</style>
